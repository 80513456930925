export const ghana = {
  name: "Ghana",
  mapID: "ghana",
  lat: 8.059229627200192,
  long: -1.0546875000000002,
  zoom: 7,
  minzoom: 7,
  currentLayer: "2",
  currentLegend: "0",
  adm2Names: null,
  adm1Names: null,
  boundaries: 2,
  /* 
    you can add as many layers for each indicator. 
    do maintain the same structure for all. 
    */
  layers: [
    {
      name: "Country Border",
      carto_tableName: "gha_adm0",
      carto_layer: null /* we will insert carto's layer object here */,
      carto_style: `#layer {polygon-fill: #826dba; polygon-opacity: 0;} #layer::outline {line-width: 1.75; line-color: #000000; line-opacity: 1;}`,
      visible: true,
      filters: [],
    },
    {
      name: "1x1km area",
      carto_tableName: "gha_class",
      carto_source: null,
      carto_layer: null /* we will insert carto's layer object here */,
      carto_style: `#layer {polygon-fill: ramp([class], (#3d4bc7, #4f9130, #bf4343, #c49755), (1, 2, 3, 4), '=', category);} #layer::outline {line-width: 0;line-color: #ffffff;line-opacity: 0;}`,
      visible: false,
      accessCounter: new Set(null),
      washCounter: new Set(null),
      socioCounter: new Set(null),
      healthCounter: new Set(null),
      filters: [
        {
          name: "Rural Typology",
          unit: "",
          type: "categorical",
          column_name: "class",
          subcategory: "accessibility",
          value: [
            {
              name: "Rural Remote",
              value: 1,
              checked: true,
            },
            {
              name: "Rural On-road",
              value: 2,
              checked: true,
            },
            {
              name: "Rural Mixed",
              value: 3,
              checked: true,
            },
            {
              name: "Urban",
              value: 4,
              checked: true,
            },
          ] /* declaure col values that should be filtered on */,
        },
        // {
        //   name: "Population Estimate",
        //   unit: "people",
        //   type: "range",
        //   column_name: "pop",
        //   min: 0,
        //   max: 24402,
        //   value: [0, 24402],
        //   subcategory: "socioeconomic",
        // },
        // {
        //   name: "Travel Time to Cities",
        //   unit: "min.",
        //   type: "range",
        //   column_name: "timec",
        //   min: 0,
        //   max: 610,
        //   value: [0, 610],
        //   subcategory: "accessibility",
        // },
        // {
        //   name: "Distance to Roads",
        //   unit: "km.",
        //   type: "range",
        //   column_name: "dr",
        //   min: 0.1,
        //   max: 39.7,
        //   value: [0.1, 39.7],
        //   subcategory: "accessibility",
        // },
        // {
        //   name: "Distance to Towns",
        //   unit: "km.",
        //   type: "range",
        //   column_name: "dt",
        //   min: 0,
        //   max: 77.4,
        //   value: [0, 77.4],
        //   subcategory: "accessibility",
        // },
      ],
    },
    {
      name: "5x5km area",
      carto_tableName: "gha_multivariable_pixel",
      carto_source: null,
      carto_layer: null,
      carto_style: `#layer {polygon-fill: ramp([class], (#3d4bc7, #4f9130, #bf4343, #c49755), (1, 2, 3, 4), '=', category);} #layer::outline {line-width: 0;line-color: #ffffff;line-opacity: 0;}`,
      visible: true,
      accessCounter: new Set(null),
      washCounter: new Set(null),
      socioCounter: new Set(null),
      healthCounter: new Set(null),
      prefix: "p_",
      filters: [
        {
          name: "Rural Typology",
          unit: "",
          type: "categorical",
          column_name: "class",
          subcategory: "accessibility",
          value: [
            {
              name: "Rural Remote",
              value: 1,
              checked: true,
            },
            {
              name: "Rural On-road",
              value: 2,
              checked: true,
            },
            {
              name: "Rural Mixed",
              value: 3,
              checked: true,
            },
            {
              name: "Urban",
              value: 4,
              checked: true,
            },
          ] /* declaure col values that should be filtered on */,
        },
        {
          name: "Population Practicing Open Defecation",
          unit: "%",
          type: "range",
          column_name: "od",
          min: 0,
          max: 99,
          value: [0, 99],
          subcategory: "wash",
        },

        {
          name: "Travel Time to Cities",
          unit: "min.",
          type: "range",
          column_name: "timec",
          min: 0,
          max: 592,
          value: [0, 592],
          subcategory: "accessibility",
        },

        {
          name: "Diahrrea Prevalence in Children <5 Years",
          unit: "%",
          type: "range",
          column_name: "dia",
          min: 2.4,
          max: 6.5,
          value: [2.4, 6.5],
          subcategory: "health",
        },
        {
          name: "Predicted Annual Cholera Incidence",
          unit: "cases/100,000pp",
          type: "range",
          column_name: "chol",
          min: 0,
          max: 6410.7,
          value: [0, 6410.7],
          subcategory: "health",
        },
        {
          name: "Reliance on Unimproved Sanitation",
          unit: "%",
          type: "range",
          column_name: "s_uimp",
          min: 0,
          max: 90,
          value: [0, 90],
          subcategory: "wash",
        },
        {
          name: "Access to Any Improved Sanitation",
          unit: "%",
          type: "range",
          column_name: "s_imp",
          min: 0,
          max: 100,
          value: [0, 100],
          subcategory: "wash",
        },
        {
          name: "Access to Non-Piped Improved Sanitation",
          unit: "%",
          type: "range",
          column_name: "s_io",
          min: 0,
          max: 96,
          value: [0, 96],
          subcategory: "wash",
        },
        {
          name: "Access to Sewer/Septic Sanitation",
          unit: "%",
          type: "range",
          column_name: "s_pipe",
          min: 0,
          max: 98,
          value: [0, 99],
          subcategory: "wash",
        },
        {
          name: "Reliance on Unimproved Drinking Water",
          unit: "%",
          type: "range",
          column_name: "w_uimp",
          min: 0,
          max: 58,
          value: [0, 58],
          subcategory: "wash",
        },
        {
          name: "Access to Any Improved Drinking Water",
          unit: "%",
          type: "range",
          column_name: "w_imp",
          min: 1,
          max: 100,
          value: [1, 100],
          subcategory: "wash",
        },
        {
          name: "Access to Non-Piped Improved Drinking Water",
          unit: "%",
          type: "range",
          column_name: "w_io",
          min: 0,
          max: 100,
          value: [0, 100],
          subcategory: "wash",
        },
        {
          name: "Access to Piped Drinking Water",
          unit: "%",
          type: "range",
          column_name: "w_pipe",
          min: 0,
          max: 100,
          value: [0, 100],
          subcategory: "wash",
        },
        {
          name: "Reliance on Surface Water",
          unit: "%",
          type: "range",
          column_name: "w_sur",
          min: 0,
          max: 93,
          value: [0, 93],
          subcategory: "wash",
        },
        {
          name: "Men's Educational Attainment",
          unit: "yrs.",
          type: "range",
          column_name: "edum",
          min: 3,
          max: 11,
          value: [3, 11],
          subcategory: "socioeconomic",
        },
        {
          name: "Women's Educational Attainment",
          unit: "yrs.",
          type: "range",
          column_name: "eduw",
          min: 2,
          max: 10,
          value: [2, 10],
          subcategory: "socioeconomic",
        },
        {
          name: "Mortality in Children <5 Years",
          unit: "%",
          type: "range",
          column_name: "u5m",
          min: 4.4,
          max: 13.3,
          value: [4.4, 13.3],
          subcategory: "health",
        },
        {
          name: "Distance to Roads",
          unit: "km.",
          type: "range",
          column_name: "dr",
          min: 0.1,
          max: 37.8,
          value: [0.1, 37.8],
          subcategory: "accessibility",
        },
        {
          name: "Distance to Towns",
          unit: "km.",
          type: "range",
          column_name: "dt",
          min: 0,
          max: 76.2,
          value: [0, 76.2],
          subcategory: "accessibility",
        },
        {
          name: "Population Estimate",
          unit: "people",
          type: "range_non_linear",
          column_name: "pop",
          min: 0,
          max: 70, //we want 7 breaks not counting start value of 0.
          value: [0, 70], //slider range will be from 0-70, which we will scale to
          scaledMin: 0,
          scaledMax: 442720,
          scaledValue: [0, 442720], //the actual min/max of column
          subcategory: "socioeconomic",
          // define 7+1 non linear marks here, note that value goes from 0-70 only
          marks: [
            {
              value: 0,
              scaledValue: 0,
              label: "0",
            },
            {
              value: 10,
              scaledValue: 100,
              label: "100",
            },
            {
              value: 20,
              scaledValue: 500,
              label: "500",
            },
            {
              value: 30,
              scaledValue: 1000,
              label: "1K",
            },
            {
              value: 40,
              scaledValue: 5000,
              label: "5K",
            },
            {
              value: 50,
              scaledValue: 50000,
              label: "50K",
            },
            {
              value: 60,
              scaledValue: 100000,
              label: "100K",
            },
            {
              value: 70,
              scaledValue: 500000,
              label: "500K",
            },
          ],
        },
        {
          name: "Rural Remote",
          unit: "%",
          type: "none",
          column_name: "rr",
          subcategory: "accessibility",
        },
        {
          name: "Rural On-road",
          unit: "%",
          type: "none",
          column_name: "rrd",
          subcategory: "accessibility",
        },
        {
          name: "Rural Mixed",
          unit: "%",
          type: "none",
          column_name: "rm",
          subcategory: "accessibility",
        },
        {
          name: "Urban",
          unit: "%",
          type: "none",
          column_name: "u",
          subcategory: "accessibility",
        },
        {
          name: "Region",
          type: "none",
          column_name: "name_1",
          subcategory: "id",
        },
        {
          name: "District",
          type: "none",
          column_name: "name_2",
          subcategory: "id",
        },
      ],
    },
    {
      name: "District",
      carto_tableName: "gha_multivariable_adm2",
      carto_source: null,
      carto_layer: null,
      carto_style: `#layer {polygon-fill: ramp([class], (#3d4bc7, #4f9130, #bf4343, #c49755), (1, 2, 3, 4), '=', category);} #layer::outline {line-width: 1;line-color: #000000;line-opacity: 1;}`,
      visible: false,
      accessCounter: new Set(null),
      washCounter: new Set(null),
      socioCounter: new Set(null),
      healthCounter: new Set(null),
      prefix: "adm2_",
      filters: [
        {
          name: "Predominant Rural Typology",
          unit: "",
          type: "categorical",
          column_name: "class",
          value: [
            {
              name: "Predominantly Rural Remote",
              value: 1,
              checked: true,
            },
            {
              name: "Predominantly Rural on-road",
              value: 2,
              checked: true,
            },
            {
              name: "Predominantly Rural Mixed",
              value: 3,
              checked: true,
            },
            {
              name: "Predominantly Urban",
              value: 4,
              checked: true,
            },
          ] /* declaure col values that should be filtered on */,
          subcategory: "accessibility",
        },
        {
          name: "Average Population Practicing Open Defecation",
          unit: "%",
          type: "range",
          column_name: "od",
          min: 11,
          max: 92,
          value: [11, 92],
          subcategory: "wash",
        },
        {
          name: "Average Travel Time to Cities",
          unit: "min.",
          type: "range",
          column_name: "timec",
          min: 0,
          max: 304,
          value: [0, 304],
          subcategory: "accessibility",
        },
        {
          name: "Average Diahrrea Prevalence in Children <5 Years",
          unit: "%",
          type: "range",
          column_name: "dia",
          min: 2.5,
          max: 5.2,
          value: [2.5, 5.5],
          subcategory: "health",
        },
        {
          name: "Average Predicted Annual Cholera Incidence",
          unit: "cases/100,000pp",
          type: "range",
          column_name: "chol",
          min: 0.1,
          max: 898.2,
          value: [0.1, 898.2],
          subcategory: "health",
        },
        {
          name: "Average Reliance on Unimproved Sanitation",
          unit: "%",
          type: "range",
          column_name: "s_uimp",
          min: 1,
          max: 34,
          value: [1, 34],
          subcategory: "wash",
        },
        {
          name: "Access to Any Improved Sanitation",
          unit: "%",
          type: "range",
          column_name: "s_imp",
          min: 7,
          max: 88,
          value: [7, 88],
          subcategory: "wash",
        },
        {
          name: "Access to Non-Piped Improved Sanitation",
          unit: "%",
          type: "range",
          column_name: "s_io",
          min: 4,
          max: 68,
          value: [4, 68],
          subcategory: "wash",
        },
        {
          name: "Access to Sewer/Septic Sanitation",
          unit: "%",
          type: "range",
          column_name: "s_pipe",
          min: 1,
          max: 69,
          value: [1, 69],
          subcategory: "wash",
        },
        {
          name: "Reliance on Unimproved Drinking Water",
          unit: "%",
          type: "range",
          column_name: "w_uimp",
          min: 0,
          max: 19,
          value: [0, 19],
          subcategory: "wash",
        },
        {
          name: "Access to Any Improved Drinking Water",
          unit: "%",
          type: "range",
          column_name: "w_imp",
          min: 60,
          max: 100,
          value: [60, 100],
          subcategory: "wash",
        },
        {
          name: "Access to Non-Piped Improved Drinking Water",
          unit: "%",
          type: "range",
          column_name: "w_io",
          min: 24,
          max: 87,
          value: [24, 87],
          subcategory: "wash",
        },
        {
          name: "Access to Piped Drinking Water",
          unit: "%",
          type: "range",
          column_name: "w_pipe",
          min: 3,
          max: 61,
          value: [3, 61],
          subcategory: "wash",
        },
        {
          name: "Reliance on Surface Water",
          unit: "%",
          type: "range",
          column_name: "w_sur",
          min: 0,
          max: 30,
          value: [0, 30],
          subcategory: "wash",
        },
        {
          name: "Average Women's Educational Attainment",
          unit: "yrs.",
          type: "range",
          column_name: "eduw",
          min: 3,
          max: 10,
          value: [3, 10],
          subcategory: "socioeconomic",
        },
        {
          name: "Average Men's Educational Attainment",
          unit: "yrs.",
          type: "range",
          column_name: "edum",
          min: 4,
          max: 11,
          value: [4, 11],
          subcategory: "socioeconomic",
        },
        {
          name: "Average Mortality in Children <5 Years",
          unit: "%",
          type: "range",
          column_name: "u5m",
          min: 4.4,
          max: 7.5,
          value: [4.4, 7.5],
          subcategory: "health",
        },
        {
          name: "Average Distance to Roads",
          unit: "km.",
          type: "range",
          column_name: "dr",
          min: 0.1,
          max: 20.5,
          value: [0.1, 20.5],
          subcategory: "accessibility",
        },
        {
          name: "Average Distance to Towns",
          unit: "km.",
          type: "range",
          column_name: "dt",
          min: 0,
          max: 47.4,
          value: [0, 47.4],
          subcategory: "accessibility",
        },
        {
          name: "Population Estimate",
          unit: "people",
          type: "range",
          column_name: "pop",
          min: 27942,
          max: 3107236,
          value: [27942, 3107236],
          subcategory: "socioeconomic",
        },
        // {
        //   name: "Average Relative Wealth Index",
        //   unit: "index",
        //   type: "range_non_linear",
        //   column_name: "rwi",
        //   scaledMin: -0.53,
        //   scaledMax: 1.35,
        //   scaledValue: [-0.53, 1.35],
        //   subcategory: "socioeconomic",
        //   min: 0,
        //   max: 60,
        //   value: [0, 60], //the actual min/max of column
        //   marks: [
        //     {
        //       value: 0,
        //       scaledValue: -0.53,
        //       label: "-0.53",
        //     },
        //     {
        //       value: 10,
        //       scaledValue: -0.25,
        //       label: "-0.25",
        //     },
        //     {
        //       value: 20,
        //       scaledValue: 0,
        //       label: "0",
        //     },
        //     {
        //       value: 30,
        //       scaledValue: 0.25,
        //       label: "0.25",
        //     },
        //     {
        //       value: 40,
        //       scaledValue: 0.6,
        //       label: "0.6",
        //     },
        //     {
        //       value: 50,
        //       scaledValue: 1,
        //       label: "1",
        //     },
        //     {
        //       value: 60,
        //       scaledValue: 1.4,
        //       label: "1.4",
        //     },
        //   ],
        // },
        {
          name: "Rural Remote",
          unit: "%",
          type: "none",
          column_name: "rr",
          subcategory: "accessibility",
        },
        {
          name: "Rural On-road",
          unit: "%",
          type: "none",
          column_name: "rrd",
          subcategory: "accessibility",
        },
        {
          name: "Rural Mixed",
          unit: "%",
          type: "none",
          column_name: "rm",
          subcategory: "accessibility",
        },
        {
          name: "Urban",
          unit: "%",
          type: "none",
          column_name: "u",
          subcategory: "accessibility",
        },
        {
          name: "Region",
          type: "none",
          column_name: "name_1",
          subcategory: "id",
        },
        {
          name: "District",
          type: "none",
          column_name: "name_2",
          subcategory: "id",
        },
      ],
    },
    {
      name: "Region",
      carto_tableName: "gha_multivariable_adm1",
      carto_layer: null /* we will insert carto's layer object here */,
      carto_source: null,
      carto_style: `#layer {polygon-fill: ramp([class], (#3d4bc7, #4f9130, #bf4343, #c49755), (1, 2, 3, 4), '=', category);} #layer::outline {line-width: 1;line-color: #000000;line-opacity: 1;}`,
      accessCounter: new Set(null),
      washCounter: new Set(null),
      socioCounter: new Set(null),
      healthCounter: new Set(null),
      visible: false,
      type: "adm1",
      filters: [
        {
          name: "Rural Typology",
          unit: "",
          type: "categorical",
          column_name: "class",
          subcategory: "accessibility",
          value: [
            {
              name: "Rural Remote",
              value: 1,
              checked: true,
            },
            {
              name: "Rural On-road",
              value: 2,
              checked: true,
            },
            {
              name: "Rural Mixed",
              value: 3,
              checked: true,
            },
            {
              name: "Urban",
              value: 4,
              checked: true,
            },
          ] /* declaure col values that should be filtered on */,
        },
        {
          name: "Population Practicing Open Defecation",
          unit: "%",
          type: "range",
          column_name: "od",
          min: 30,
          max: 78,
          value: [30, 78],
          subcategory: "wash",
        },
        {
          name: "Travel Time to Cities",
          unit: "min.",
          type: "range",
          column_name: "timec",
          min: 27,
          max: 175,
          value: [27, 175],
          subcategory: "accessibility",
        },
        {
          name: "Diahrrea Prevalence in Children <5 Years",
          unit: "%",
          type: "range",
          column_name: "dia",
          min: 2.7,
          max: 5.1,
          value: [2.7, 5.1],
          subcategory: "health",
        },
        {
          name: "Predicted Annual Cholera Incidence",
          unit: "cases/100,000pp",
          type: "range",
          column_name: "chol",
          min: 1.6,
          max: 430,
          value: [1.6, 430],
          subcategory: "health",
        },
        {
          name: "Reliance on Unimproved Sanitation",
          unit: "%",
          type: "range",
          column_name: "s_uimp",
          min: 3,
          max: 22,
          value: [3, 22],
          subcategory: "wash",
        },
        {
          name: "Access to Any Improved Sanitation",
          unit: "%",
          type: "range",
          column_name: "s_imp",
          min: 19,
          max: 56,
          value: [19, 56],
          subcategory: "wash",
        },
        {
          name: "Access to Non-Piped Improved Sanitation",
          unit: "%",
          type: "range",
          column_name: "s_io",
          min: 17,
          max: 48,
          value: [17, 48],
          subcategory: "wash",
        },
        {
          name: "Access to Sewer/Septic Sanitation",
          unit: "%",
          type: "range",
          column_name: "s_pipe",
          min: 2,
          max: 16,
          value: [2, 16],
          subcategory: "wash",
        },
        {
          name: "Reliance on Unimproved Drinking Water",
          unit: "%",
          type: "range",
          column_name: "w_uimp",
          min: 3,
          max: 11,
          value: [3, 11],
          subcategory: "wash",
        },
        {
          name: "Access to Any Improved Drinking Water",
          unit: "%",
          type: "range",
          column_name: "w_imp",
          min: 75,
          max: 90,
          value: [75, 90],
          subcategory: "wash",
        },
        {
          name: "Access to Non-Piped Improved Drinking Water",
          unit: "%",
          type: "range",
          column_name: "w_io",
          min: 39,
          max: 68,
          value: [39, 68],
          subcategory: "wash",
        },
        {
          name: "Access to Piped Drinking Water",
          unit: "%",
          type: "range",
          column_name: "w_pipe",
          min: 13,
          max: 40,
          value: [13, 40],
          subcategory: "wash",
        },
        {
          name: "Reliance on Surface Water",
          unit: "%",
          type: "range",
          column_name: "w_sur",
          min: 7,
          max: 19,
          value: [7, 19],
          subcategory: "wash",
        },
        {
          name: "Women's Educational Attainment",
          unit: "yrs.",
          type: "range",
          column_name: "eduw",
          min: 3,
          max: 7,
          value: [3, 7],
          subcategory: "socioeconomic",
        },
        {
          name: "Men's Educational Attainment",
          unit: "yrs.",
          type: "range",
          column_name: "edum",
          min: 5,
          max: 8,
          value: [5, 8],
          subcategory: "socioeconomic",
        },
        {
          name: "Mortality in Children <5 Years",
          unit: "%",
          type: "range",
          column_name: "u5m",
          min: 5.3,
          max: 6.8,
          value: [5.3, 6.8],
          subcategory: "health",
        },
        {
          name: "Distance to Roads",
          unit: "km.",
          type: "range",
          column_name: "dr",
          min: 1.4,
          max: 10.1,
          value: [1.4, 10.1],
          subcategory: "accessibility",
        },
        {
          name: "Distance to Towns",
          unit: "km.",
          type: "range",
          column_name: "dt",
          min: 3.7,
          max: 31.1,
          value: [3.7, 31.1],
          subcategory: "accessibility",
        },
        {
          name: "Population Estimate",
          unit: "people",
          type: "range",
          column_name: "pop",
          min: 605197,
          max: 6755392,
          value: [605197, 6755392], //the actual min/max of column
          subcategory: "socioeconomic",
        },
        {
          name: "Rural Remote",
          unit: "%",
          type: "none",
          column_name: "rr",
          subcategory: "accessibility",
        },
        {
          name: "Rural On-road",
          unit: "%",
          type: "none",
          column_name: "rrd",
          subcategory: "accessibility",
        },
        {
          name: "Rural Mixed",
          unit: "%",
          type: "none",
          column_name: "rm",
          subcategory: "accessibility",
        },
        {
          name: "Urban",
          unit: "%",
          type: "none",
          column_name: "u",
          subcategory: "accessibility",
        },
        {
          name: "Province",
          type: "none",
          column_name: "name_1",
          subcategory: "id",
        },
      ],
    },
    {
      name: "Estimated settlement",
      carto_tableName: "gha_multivariable_comms_join2",
      carto_layer: null /* we will insert carto's layer object here */,
      carto_source: null,
      carto_style: `#layer {polygon-fill: #826dba; polygon-opacity: 0;} #layer::outline {line-width: 1; line-color: #000000; line-opacity: 1;}`,
      accessCounter: new Set(null),
      washCounter: new Set(null),
      socioCounter: new Set(null),
      healthCounter: new Set(null),
      visible: false,
      filters: [
        {
          name: "Rural Typology",
          unit: "",
          type: "categorical",
          column_name: "class",
          subcategory: "accessibility",
          value: [
            {
              name: "Rural Remote",
              value: 1,
              checked: true,
            },
            {
              name: "Rural On-road",
              value: 2,
              checked: true,
            },
            {
              name: "Rural Mixed",
              value: 3,
              checked: true,
            },
            {
              name: "Urban",
              value: 4,
              checked: true,
            },
          ] /* declaure col values that should be filtered on */,
        },
        {
          name: "Population Practicing Open Defecation",
          unit: "%",
          type: "range",
          column_name: "od",
          min: 0,
          max: 100,
          value: [0, 100],
          subcategory: "wash",
        },
        {
          name: "Travel Time to Cities",
          unit: "min.",
          type: "range",
          column_name: "timec",
          min: 0,
          max: 546,
          value: [0, 546],
          subcategory: "accessibility",
        },
        {
          name: "Diahrrea Prevalence in Children <5 Years",
          unit: "%",
          type: "range",
          column_name: "dia",
          min: 2.3,
          max: 6.7,
          value: [2.3, 6.7],
          subcategory: "health",
        },
        {
          name: "Predicted Annual Cholera Incidence",
          unit: "cases/100,000pp",
          type: "range",
          column_name: "chol",
          min: 0,
          max: 6410.7,
          value: [0, 6410.7],
          subcategory: "health",
        },
        {
          name: "Reliance on Unimproved Sanitation",
          unit: "%",
          type: "range",
          column_name: "s_uimp",
          min: 0,
          max: 97,
          value: [0, 97],
          subcategory: "wash",
        },
        {
          name: "Reliance on Unimproved Drinking Water",
          unit: "%",
          type: "range",
          column_name: "w_uimp",
          min: 0,
          max: 80,
          value: [0, 80],
          subcategory: "wash",
        },
        {
          name: "Women's Educational Attainment",
          unit: "yrs.",
          type: "range",
          column_name: "eduw",
          min: 1,
          max: 10,
          value: [1, 10],
          subcategory: "socioeconomic",
        },
        {
          name: "Men's Educational Attainment",
          unit: "yrs.",
          type: "range",
          column_name: "edum",
          min: 3,
          max: 11,
          value: [3, 11],
          subcategory: "socioeconomic",
        },
        {
          name: "Mortality in Children <5 Years",
          unit: "%",
          type: "range",
          column_name: "u5m",
          min: 4.3,
          max: 12.7,
          value: [4.3, 12.7],
          subcategory: "health",
        },
        {
          name: "Distance to Roads",
          unit: "km.",
          type: "range",
          column_name: "dr",
          min: 0,
          max: 37.1,
          value: [0, 37.1],
          subcategory: "accessibility",
        },
        {
          name: "Distance to Towns",
          unit: "km.",
          type: "range",
          column_name: "dt",
          min: 0,
          max: 76.2,
          value: [0, 76.2],
          subcategory: "accessibility",
        },
        {
          name: "Population Estimate",
          unit: "people",
          type: "range_non_linear",
          column_name: "pop",
          min: 0,
          max: 70, //we want 7 breaks not counting start value of 0.
          value: [0, 70], //slider range will be from 0-70, which we will scale to
          scaledMin: 0,
          scaledMax: 5722595,
          scaledValue: [0, 5722595], //the actual min/max of column
          subcategory: "socioeconomic",
          // define 7+1 non linear marks here, note that value goes from 0-70 only
          marks: [
            {
              value: 0,
              scaledValue: 0,
              label: "0",
            },
            {
              value: 10,
              scaledValue: 500,
              label: "500",
            },
            {
              value: 20,
              scaledValue: 1000,
              label: "1K",
            },
            {
              value: 30,
              scaledValue: 5000,
              label: "5K",
            },
            {
              value: 40,
              scaledValue: 50000,
              label: "50K",
            },
            {
              value: 50,
              scaledValue: 100000,
              label: "100K",
            },
            {
              value: 60,
              scaledValue: 1000000,
              label: "1M",
            },
            {
              value: 70,
              scaledValue: 6000000,
              label: "6M",
            },
          ],
        },
        {
          name: "Rural Remote",
          unit: "%",
          type: "none",
          column_name: "rr",
          subcategory: "accessibility",
        },
        {
          name: "Rural On-road",
          unit: "%",
          type: "none",
          column_name: "rrd",
          subcategory: "accessibility",
        },
        {
          name: "Rural Mixed",
          unit: "%",
          type: "none",
          column_name: "rm",
          subcategory: "accessibility",
        },
        {
          name: "Urban",
          unit: "%",
          type: "none",
          column_name: "u",
          subcategory: "accessibility",
        },
        {
          name: "Region",
          type: "none",
          column_name: "name_1",
          subcategory: "id",
        },
        {
          name: "District",
          type: "none",
          column_name: "name_2",
          subcategory: "id",
        },
        {
          name: "Rural Typology",
          unit: "",
          type: "categorical",
          column_name: "adm1_class",
          subcategory: "accessibility",
          value: [
            {
              name: "Rural Remote",
              value: 1,
              checked: true,
            },
            {
              name: "Rural On-road",
              value: 2,
              checked: true,
            },
            {
              name: "Rural Mixed",
              value: 3,
              checked: true,
            },
            {
              name: "Urban",
              value: 4,
              checked: true,
            },
          ] /* declaure col values that should be filtered on */,
        },
        {
          name: "Population Practicing Open Defecation",
          unit: "%",
          type: "range",
          column_name: "adm1_od",
          min: 30,
          max: 78,
          value: [30, 78],
          subcategory: "wash",
        },
        {
          name: "Travel Time to Cities",
          unit: "min.",
          type: "range",
          column_name: "adm1_timec",
          min: 27,
          max: 175,
          value: [27, 175],
          subcategory: "accessibility",
        },
        {
          name: "Diahrrea Prevalence in Children <5 Years",
          unit: "%",
          type: "range",
          column_name: "adm1_dia",
          min: 2.7,
          max: 5.1,
          value: [2.7, 5.1],
          subcategory: "health",
        },
        {
          name: "Predicted Annual Cholera Incidence",
          unit: "cases/100,000pp",
          type: "range",
          column_name: "adm1_chol",
          min: 1.6,
          max: 430,
          value: [1.6, 430],
          subcategory: "health",
        },
        {
          name: "Reliance on Unimproved Sanitation",
          unit: "%",
          type: "range",
          column_name: "adm1_s_uimp",
          min: 3,
          max: 22,
          value: [3, 22],
          subcategory: "wash",
        },
        {
          name: "Access to Any Improved Sanitation",
          unit: "%",
          type: "range",
          column_name: "adm1_s_imp",
          min: 19,
          max: 56,
          value: [19, 56],
          subcategory: "wash",
        },
        {
          name: "Access to Non-Piped Improved Sanitation",
          unit: "%",
          type: "range",
          column_name: "adm1_s_io",
          min: 17,
          max: 48,
          value: [17, 48],
          subcategory: "wash",
        },
        {
          name: "Access to Sewer/Septic Sanitation",
          unit: "%",
          type: "range",
          column_name: "adm1_s_pipe",
          min: 2,
          max: 16,
          value: [2, 16],
          subcategory: "wash",
        },
        {
          name: "Reliance on Unimproved Drinking Water",
          unit: "%",
          type: "range",
          column_name: "adm1_w_uimp",
          min: 3,
          max: 11,
          value: [3, 11],
          subcategory: "wash",
        },
        {
          name: "Access to Any Improved Drinking Water",
          unit: "%",
          type: "range",
          column_name: "adm1_w_imp",
          min: 75,
          max: 90,
          value: [75, 90],
          subcategory: "wash",
        },
        {
          name: "Access to Non-Piped Improved Drinking Water",
          unit: "%",
          type: "range",
          column_name: "adm1_w_io",
          min: 39,
          max: 68,
          value: [39, 68],
          subcategory: "wash",
        },
        {
          name: "Access to Piped Drinking Water",
          unit: "%",
          type: "range",
          column_name: "adm1_w_pipe",
          min: 13,
          max: 40,
          value: [13, 40],
          subcategory: "wash",
        },
        {
          name: "Reliance on Surface Water",
          unit: "%",
          type: "range",
          column_name: "adm1_w_sur",
          min: 7,
          max: 19,
          value: [7, 19],
          subcategory: "wash",
        },
        {
          name: "Women's Educational Attainment",
          unit: "yrs.",
          type: "range",
          column_name: "adm1_eduw",
          min: 3,
          max: 7,
          value: [3, 7],
          subcategory: "socioeconomic",
        },
        {
          name: "Men's Educational Attainment",
          unit: "yrs.",
          type: "range",
          column_name: "adm1_edum",
          min: 5,
          max: 8,
          value: [5, 8],
          subcategory: "socioeconomic",
        },
        {
          name: "Mortality in Children <5 Years",
          unit: "%",
          type: "range",
          column_name: "adm1_u5m",
          min: 5.3,
          max: 6.8,
          value: [5.3, 6.8],
          subcategory: "health",
        },
        {
          name: "Distance to Roads",
          unit: "km.",
          type: "range",
          column_name: "adm1_dr",
          min: 1.4,
          max: 10.1,
          value: [1.4, 10.1],
          subcategory: "accessibility",
        },
        {
          name: "Distance to Towns",
          unit: "km.",
          type: "range",
          column_name: "adm1_dt",
          min: 3.7,
          max: 31.1,
          value: [3.7, 31.1],
          subcategory: "accessibility",
        },
        {
          name: "Population Estimate",
          unit: "people",
          type: "range",
          column_name: "adm1_pop",
          min: 605197,
          max: 6755392,
          value: [605197, 6755392], //the actual min/max of column
          subcategory: "socioeconomic",
        },
        {
          name: "Rural Remote",
          unit: "%",
          type: "none",
          column_name: "adm1_rr",
          subcategory: "accessibility",
        },
        {
          name: "Rural On-road",
          unit: "%",
          type: "none",
          column_name: "adm1_rrd",
          subcategory: "accessibility",
        },
        {
          name: "Rural Mixed",
          unit: "%",
          type: "none",
          column_name: "adm1_rm",
          subcategory: "accessibility",
        },
        {
          name: "Urban",
          unit: "%",
          type: "none",
          column_name: "adm1_u",
          subcategory: "accessibility",
        },
        {
          name: "Predominant Rural Typology",
          unit: "",
          type: "categorical",
          column_name: "adm2_class",
          value: [
            {
              name: "Predominantly Rural Remote",
              value: 1,
              checked: true,
            },
            {
              name: "Predominantly Rural on-road",
              value: 2,
              checked: true,
            },
            {
              name: "Predominantly Rural Mixed",
              value: 3,
              checked: true,
            },
            {
              name: "Predominantly Urban",
              value: 4,
              checked: true,
            },
          ] /* declaure col values that should be filtered on */,
          subcategory: "accessibility",
        },
        {
          name: "Average Population Practicing Open Defecation",
          unit: "%",
          type: "range",
          column_name: "adm2_od",
          min: 11,
          max: 92,
          value: [11, 92],
          subcategory: "wash",
        },
        {
          name: "Average Travel Time to Cities",
          unit: "min.",
          type: "range",
          column_name: "adm2_timec",
          min: 0,
          max: 304,
          value: [0, 304],
          subcategory: "accessibility",
        },
        {
          name: "Average Diahrrea Prevalence in Children <5 Years",
          unit: "%",
          type: "range",
          column_name: "adm2_dia",
          min: 2.5,
          max: 5.2,
          value: [2.5, 5.5],
          subcategory: "health",
        },
        {
          name: "Average Predicted Annual Cholera Incidence",
          unit: "cases/100,000pp",
          type: "range",
          column_name: "adm2_chol",
          min: 0.1,
          max: 898.2,
          value: [0.1, 898.2],
          subcategory: "health",
        },
        {
          name: "Average Reliance on Unimproved Sanitation",
          unit: "%",
          type: "range",
          column_name: "adm2_s_uimp",
          min: 1,
          max: 34,
          value: [1, 34],
          subcategory: "wash",
        },
        {
          name: "Access to Any Improved Sanitation",
          unit: "%",
          type: "range",
          column_name: "adm2_s_imp",
          min: 7,
          max: 88,
          value: [7, 88],
          subcategory: "wash",
        },
        {
          name: "Access to Non-Piped Improved Sanitation",
          unit: "%",
          type: "range",
          column_name: "adm2_s_io",
          min: 4,
          max: 68,
          value: [4, 68],
          subcategory: "wash",
        },
        {
          name: "Access to Sewer/Septic Sanitation",
          unit: "%",
          type: "range",
          column_name: "adm2_s_pipe",
          min: 1,
          max: 69,
          value: [1, 69],
          subcategory: "wash",
        },
        {
          name: "Reliance on Unimproved Drinking Water",
          unit: "%",
          type: "range",
          column_name: "adm2_w_uimp",
          min: 0,
          max: 19,
          value: [0, 19],
          subcategory: "wash",
        },
        {
          name: "Access to Any Improved Drinking Water",
          unit: "%",
          type: "range",
          column_name: "adm2_w_imp",
          min: 60,
          max: 100,
          value: [60, 100],
          subcategory: "wash",
        },
        {
          name: "Access to Non-Piped Improved Drinking Water",
          unit: "%",
          type: "range",
          column_name: "adm2_w_io",
          min: 24,
          max: 87,
          value: [24, 87],
          subcategory: "wash",
        },
        {
          name: "Access to Piped Drinking Water",
          unit: "%",
          type: "range",
          column_name: "adm2_w_pipe",
          min: 3,
          max: 61,
          value: [3, 61],
          subcategory: "wash",
        },
        {
          name: "Reliance on Surface Water",
          unit: "%",
          type: "range",
          column_name: "adm2_w_sur",
          min: 0,
          max: 30,
          value: [0, 30],
          subcategory: "wash",
        },
        {
          name: "Average Women's Educational Attainment",
          unit: "yrs.",
          type: "range",
          column_name: "adm2_eduw",
          min: 3,
          max: 10,
          value: [3, 10],
          subcategory: "socioeconomic",
        },
        {
          name: "Average Men's Educational Attainment",
          unit: "yrs.",
          type: "range",
          column_name: "adm2_edum",
          min: 4,
          max: 11,
          value: [4, 11],
          subcategory: "socioeconomic",
        },
        {
          name: "Average Mortality in Children <5 Years",
          unit: "%",
          type: "range",
          column_name: "adm2_u5m",
          min: 4.4,
          max: 7.5,
          value: [4.4, 7.5],
          subcategory: "health",
        },
        {
          name: "Average Distance to Roads",
          unit: "km.",
          type: "range",
          column_name: "adm2_dr",
          min: 0.1,
          max: 20.5,
          value: [0.1, 20.5],
          subcategory: "accessibility",
        },
        {
          name: "Average Distance to Towns",
          unit: "km.",
          type: "range",
          column_name: "adm2_dt",
          min: 0,
          max: 47.4,
          value: [0, 47.4],
          subcategory: "accessibility",
        },
        {
          name: "Population Estimate",
          unit: "people",
          type: "range",
          column_name: "adm2_pop",
          min: 27942,
          max: 3107236,
          value: [27942, 3107236],
          subcategory: "socioeconomic",
        },
        {
          name: "Rural Remote",
          unit: "%",
          type: "none",
          column_name: "adm2_rr",
          subcategory: "accessibility",
        },
        {
          name: "Rural On-road",
          unit: "%",
          type: "none",
          column_name: "adm2_rrd",
          subcategory: "accessibility",
        },
        {
          name: "Rural Mixed",
          unit: "%",
          type: "none",
          column_name: "adm2_rm",
          subcategory: "accessibility",
        },
        {
          name: "Urban",
          unit: "%",
          type: "none",
          column_name: "adm2_u",
          subcategory: "accessibility",
        },
        {
          name: "Rural Typology",
          unit: "",
          type: "categorical",
          column_name: "p_class",
          subcategory: "accessibility",
          value: [
            {
              name: "Rural Remote",
              value: 1,
              checked: true,
            },
            {
              name: "Rural On-road",
              value: 2,
              checked: true,
            },
            {
              name: "Rural Mixed",
              value: 3,
              checked: true,
            },
            {
              name: "Urban",
              value: 4,
              checked: true,
            },
          ] /* declaure col values that should be filtered on */,
        },
        {
          name: "Population Practicing Open Defecation",
          unit: "%",
          type: "range",
          column_name: "p_od",
          min: 0,
          max: 99,
          value: [0, 99],
          subcategory: "wash",
        },

        {
          name: "Travel Time to Cities",
          unit: "min.",
          type: "range",
          column_name: "p_timec",
          min: 0,
          max: 592,
          value: [0, 592],
          subcategory: "accessibility",
        },

        {
          name: "Diahrrea Prevalence in Children <5 Years",
          unit: "%",
          type: "range",
          column_name: "p_dia",
          min: 2.4,
          max: 6.5,
          value: [2.4, 6.5],
          subcategory: "health",
        },
        {
          name: "Predicted Annual Cholera Incidence",
          unit: "cases/100,000pp",
          type: "range",
          column_name: "p_chol",
          min: 0,
          max: 6410.7,
          value: [0, 6410.7],
          subcategory: "health",
        },
        {
          name: "Reliance on Unimproved Sanitation",
          unit: "%",
          type: "range",
          column_name: "p_s_uimp",
          min: 0,
          max: 90,
          value: [0, 90],
          subcategory: "wash",
        },
        {
          name: "Access to Any Improved Sanitation",
          unit: "%",
          type: "range",
          column_name: "p_s_imp",
          min: 0,
          max: 100,
          value: [0, 100],
          subcategory: "wash",
        },
        {
          name: "Access to Non-Piped Improved Sanitation",
          unit: "%",
          type: "range",
          column_name: "p_s_io",
          min: 0,
          max: 96,
          value: [0, 96],
          subcategory: "wash",
        },
        {
          name: "Access to Sewer/Septic Sanitation",
          unit: "%",
          type: "range",
          column_name: "p_s_pipe",
          min: 0,
          max: 98,
          value: [0, 99],
          subcategory: "wash",
        },
        {
          name: "Reliance on Unimproved Drinking Water",
          unit: "%",
          type: "range",
          column_name: "p_w_uimp",
          min: 0,
          max: 58,
          value: [0, 58],
          subcategory: "wash",
        },
        {
          name: "Access to Any Improved Drinking Water",
          unit: "%",
          type: "range",
          column_name: "p_w_imp",
          min: 1,
          max: 100,
          value: [1, 100],
          subcategory: "wash",
        },
        {
          name: "Access to Non-Piped Improved Drinking Water",
          unit: "%",
          type: "range",
          column_name: "p_w_io",
          min: 0,
          max: 100,
          value: [0, 100],
          subcategory: "wash",
        },
        {
          name: "Access to Piped Drinking Water",
          unit: "%",
          type: "range",
          column_name: "p_w_pipe",
          min: 0,
          max: 100,
          value: [0, 100],
          subcategory: "wash",
        },
        {
          name: "Reliance on Surface Water",
          unit: "%",
          type: "range",
          column_name: "p_w_sur",
          min: 0,
          max: 93,
          value: [0, 93],
          subcategory: "wash",
        },
        {
          name: "Men's Educational Attainment",
          unit: "yrs.",
          type: "range",
          column_name: "p_edum",
          min: 3,
          max: 11,
          value: [3, 11],
          subcategory: "socioeconomic",
        },
        {
          name: "Women's Educational Attainment",
          unit: "yrs.",
          type: "range",
          column_name: "p_eduw",
          min: 2,
          max: 10,
          value: [2, 10],
          subcategory: "socioeconomic",
        },
        {
          name: "Mortality in Children <5 Years",
          unit: "%",
          type: "range",
          column_name: "p_u5m",
          min: 4.4,
          max: 13.3,
          value: [4.4, 13.3],
          subcategory: "health",
        },
        {
          name: "Distance to Roads",
          unit: "km.",
          type: "range",
          column_name: "p_dr",
          min: 0.1,
          max: 37.8,
          value: [0.1, 37.8],
          subcategory: "accessibility",
        },
        {
          name: "Distance to Towns",
          unit: "km.",
          type: "range",
          column_name: "p_dt",
          min: 0,
          max: 76.2,
          value: [0, 76.2],
          subcategory: "accessibility",
        },
        {
          name: "Population Estimate",
          unit: "people",
          type: "range_non_linear",
          column_name: "p_pop",
          min: 0,
          max: 70, //we want 7 breaks not counting start value of 0.
          value: [0, 70], //slider range will be from 0-70, which we will scale to
          scaledMin: 0,
          scaledMax: 442720,
          scaledValue: [0, 442720], //the actual min/max of column
          subcategory: "socioeconomic",
          // define 7+1 non linear marks here, note that value goes from 0-70 only
          marks: [
            {
              value: 0,
              scaledValue: 0,
              label: "0",
            },
            {
              value: 10,
              scaledValue: 100,
              label: "100",
            },
            {
              value: 20,
              scaledValue: 500,
              label: "500",
            },
            {
              value: 30,
              scaledValue: 1000,
              label: "1K",
            },
            {
              value: 40,
              scaledValue: 5000,
              label: "5K",
            },
            {
              value: 50,
              scaledValue: 50000,
              label: "50K",
            },
            {
              value: 60,
              scaledValue: 100000,
              label: "100K",
            },
            {
              value: 70,
              scaledValue: 500000,
              label: "500K",
            },
          ],
        },
        {
          name: "Rural Remote",
          unit: "%",
          type: "none",
          column_name: "p_rr",
          subcategory: "accessibility",
        },
        {
          name: "Rural On-road",
          unit: "%",
          type: "none",
          column_name: "p_rrd",
          subcategory: "accessibility",
        },
        {
          name: "Rural Mixed",
          unit: "%",
          type: "none",
          column_name: "p_rm",
          subcategory: "accessibility",
        },
        {
          name: "Urban",
          unit: "%",
          type: "none",
          column_name: "p_u",
          subcategory: "accessibility",
        },
      ],
    },
  ],
};
