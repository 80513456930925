export const legendStyles = [
  {
    name_pixel: "Rural Typology",
    name_bounds: "Predominant Rural Typology",
    style_pixel: `#layer {polygon-fill: ramp([class], (#3d4bc7, #4f9130, #bf4343, #c49755), (1, 2, 3, 4), '=', category);} #layer::outline {line-width: 0;line-color: #ffffff;line-opacity: 0;}`,
    style_bounds: `#layer {polygon-fill: ramp([class], (#3d4bc7, #4f9130, #bf4343, #c49755), (1, 2, 3, 4), '=', category);} #layer::outline {line-width: 1;line-color: #000000;line-opacity: 1;}`,
  },
  {
    name_pixel: "Population Practicing Open Defecation",
    name_bounds: "Average Population Practicing Open Defecation",
    style_pixel: `#layer {polygon-fill: ramp([od], (#d4e09b, #f6f4d2, #cbdfbd, #f19c79, #a44a3f), jenks);} #layer::outline {line-width: 0;line-color: #ffffff;line-opacity: 0;}`,
    style_bounds: `#layer {polygon-fill: ramp([od], (#d4e09b, #f6f4d2, #cbdfbd, #f19c79, #a44a3f), jenks);} #layer::outline {line-width: 1;line-color: #000000;line-opacity: 1;}`,
  },
  {
    name_pixel: "Travel Time to Cities",
    name_bounds: "Average Travel Time to Cities",
    style_pixel: `#layer {polygon-fill: ramp([timec], (#006d77, #83c5be, #edf6f9, #ffddd2, #e29578), jenks);} #layer::outline {line-width: 0;line-color: #ffffff;line-opacity: 0;}`,
    style_bounds: `#layer {polygon-fill: ramp([timec], (#006d77, #83c5be, #edf6f9, #ffddd2, #e29578), jenks);} #layer::outline {line-width: 1;line-color: #000000;line-opacity: 1;}`,
  },
  {
    name_pixel: "Diahrrea Prevalence in Children <5 Years",
    name_bounds: "Average Diahrrea Prevalence in Children <5 Years",
    style_pixel: `#layer {polygon-fill: ramp([dia], (#22223b, #4a4e69, #9a8c98, #c9ada7, #f2e9e4), jenks);} #layer::outline {line-width: 0;line-color: #ffffff;line-opacity: 0;}`,
    style_bounds: `#layer {polygon-fill: ramp([dia], (#22223b, #4a4e69, #9a8c98, #c9ada7, #f2e9e4), jenks);} #layer::outline {line-width: 1;line-color: #000000;line-opacity: 1;}`,
  },
  {
    name_pixel: "Predicted Annual Cholera Incidence",
    name_bounds: "Average Predicted Annual Cholera Incidence",
    style_pixel: `#layer {polygon-fill: ramp([chol], (#5f0f40, #9a031e, #fb8b24, #e36414, #0f4c5c), jenks);} #layer::outline {line-width: 0;line-color: #ffffff;line-opacity: 0;}`,
    style_bounds: `#layer {polygon-fill: ramp([chol], (#5f0f40, #9a031e, #fb8b24, #e36414, #0f4c5c), jenks);} #layer::outline {line-width: 1;line-color: #000000;line-opacity: 1;}`,
  },
  {
    name_pixel: "Reliance on Unimproved Sanitation",
    name_bounds: "Average Reliance on Unimproved Sanitation",
    style_pixel: `#layer {polygon-fill: ramp([s_uimp], (#335c67, #fff3b0, #e09f3e, #9e2a2b, #540b0e), quantiles);} #layer::outline {line-width: 0;line-color: #ffffff;line-opacity: 0;}`,
    style_bounds: `#layer {polygon-fill: ramp([s_uimp], (#335c67, #fff3b0, #e09f3e, #9e2a2b, #540b0e), quantiles);} #layer::outline {line-width: 1;line-color: #000000;line-opacity: 1;}`,
  },
  {
    name_pixel: "Access to Any Improved Sanitation",
    name_bounds: "Average Access to Any Improved Sanitation",
    style_pixel: `#layer {polygon-fill: ramp([s_imp], (#006d77, #83c5be, #edf6f9, #ffddd2, #e29578), jenks);} #layer::outline {line-width: 0;line-color: #ffffff;line-opacity: 0;}`,
    style_bounds: `#layer {polygon-fill: ramp([s_imp], (#006d77, #83c5be, #edf6f9, #ffddd2, #e29578), jenks);} #layer::outline {line-width: 1;line-color: #000000;line-opacity: 1;}`,
  },
  {
    name_pixel: "Access to Non-Piped Improved Sanitation",
    name_bounds: "Average Access to Non-Piped Improved Sanitation",
    style_pixel: `#layer {polygon-fill: ramp([s_io], (#264653, #2a9d8f, #e9c46a, #f4a261, #e76f51), jenks);} #layer::outline {line-width: 0;line-color: #ffffff;line-opacity: 0;}`,
    style_bounds: `#layer {polygon-fill: ramp([s_io], (#264653, #2a9d8f, #e9c46a, #f4a261, #e76f51), jenks);} #layer::outline {line-width: 1;line-color: #000000;line-opacity: 1;}`,
  },
  {
    name_pixel: "Access to Sewer/Septic Sanitation",
    name_bounds: "Average Access to Sewer/Septic Sanitation",
    style_pixel: `#layer {polygon-fill: ramp([s_pipe], (#feebe2, #fbb4b9, #f768a1, #c51b8a, #7a0177), jenks);} #layer::outline {line-width: 0;line-color: #ffffff;line-opacity: 0;}`,
    style_bounds: `#layer {polygon-fill: ramp([s_pipe], (#feebe2, #fbb4b9, #f768a1, #c51b8a, #7a0177), jenks);} #layer::outline {line-width: 1;line-color: #000000;line-opacity: 1;}`,
  },
  {
    name_pixel: "Reliance on Unimproved Drinking Water",
    nam_bounds: "Average Reliance on Unimproved Drinking Water",
    style_pixel: `#layer {polygon-fill: ramp([w_uimp], (#003049, #d62828, #f77f00, #fcbf49, #eae2b7), jenks);} #layer::outline {line-width: 0;line-color: #ffffff;line-opacity: 0;}`,
    style_bounds: `#layer {polygon-fill: ramp([w_uimp], (#003049, #d62828, #f77f00, #fcbf49, #eae2b7), jenks);} #layer::outline {line-width: 1;line-color: #000000;line-opacity: 1;}`,
  },
  {
    name_pixel: "Access to Any Improved Drinking Water",
    name_bounds: "Average Access to Any Improved Drinking Water",
    style_pixel: `#layer {polygon-fill: ramp([w_imp], (#233d4d, #fe7f2d, #fcca46, #a1c181, #619b8a), jenks);} #layer::outline {line-width: 0;line-color: #ffffff;line-opacity: 0;}`,
    style_bounds: `#layer {polygon-fill: ramp([w_imp], (#233d4d, #fe7f2d, #fcca46, #a1c181, #619b8a), jenks);} #layer::outline {line-width: 1;line-color: #000000;line-opacity: 1;}`,
  },
  {
    name_pixel: "Access to Non-Piped Improved Drinking Water",
    name_bounds: "Average Access to Non-Piped Improved Drinking Water",
    style_pixel: `#layer {polygon-fill: ramp([w_io], (#5f0f40, #9a031e, #fb8b24, #e36414, #0f4c5c), jenks);} #layer::outline {line-width: 0;line-color: #ffffff;line-opacity: 0;}`,
    style_bounds: `#layer {polygon-fill: ramp([w_io], (#5f0f40, #9a031e, #fb8b24, #e36414, #0f4c5c), jenks);} #layer::outline {line-width: 1;line-color: #000000;line-opacity: 1;}`,
  },
  {
    name_pixel: "Access to Piped Drinking Water",
    name_bounds: "Average Access to Piped Drinking Water",
    style_pixel: `#layer {polygon-fill: ramp([w_pipe], (#132a13, #31572c, #4f772d, #90a955, #ecf39e), jenks);} #layer::outline {line-width: 0;line-color: #ffffff;line-opacity: 0;}`,
    style_bounds: `#layer {polygon-fill: ramp([w_pipe], (#132a13, #31572c, #4f772d, #90a955, #ecf39e), jenks);} #layer::outline {line-width: 1;line-color: #000000;line-opacity: 1;}`,
  },
  {
    name_pixel: "Reliance on Surface Water",
    name_bounds: "Average Reliance on Surface Water",
    style_pixel: `#layer {polygon-fill: ramp([w_sur], (#461220, #8c2f39, #b23a48, #fcb9b2, #fed0bb), jenks);} #layer::outline {line-width: 0;line-color: #ffffff;line-opacity: 0;}`,
    style_bounds: `#layer {polygon-fill: ramp([w_sur], (#461220, #8c2f39, #b23a48, #fcb9b2, #fed0bb), jenks);} #layer::outline {line-width: 1;line-color: #000000;line-opacity: 1;}`,
  },
  {
    name_pixel: "Men's Educational Attainment",
    name_bounds: "Average Men's Educational Attainment",
    style_pixel: `#layer {polygon-fill: ramp([edum], (#132a13, #31572c, #4f772d, #90a955, #ecf39e), jenks);} #layer::outline {line-width: 0;line-color: #ffffff;line-opacity: 0;}`,
    style_bounds: `#layer {polygon-fill: ramp([edum], (#132a13, #31572c, #4f772d, #90a955, #ecf39e), jenks);} #layer::outline {line-width: 1;line-color: #000000;line-opacity: 1;}`,
  },
  {
    name_pixel: "Women's Educational Attainment",
    name_bounds: "Average Women's Educational Attainment",
    style_pixel: `#layer {polygon-fill: ramp([eduw], (#c9cba3, #ffe1a8, #e26d5c, #723d46, #472d30), jenks);} #layer::outline {line-width: 0;line-color: #ffffff;line-opacity: 0;}`,
    style_bounds: `#layer {polygon-fill: ramp([eduw], (#c9cba3, #ffe1a8, #e26d5c, #723d46, #472d30), jenks);} #layer::outline {line-width: 1;line-color: #000000;line-opacity: 1;}`,
  },
  {
    name_pixel: "Mortality in Children <5 Years",
    name_bounds: "Average Mortality in Children <5 Years",
    style_pixel: `#layer {polygon-fill: ramp([u5m], (#461220, #8c2f39, #b23a48, #fcb9b2, #fed0bb), jenks);} #layer::outline {line-width: 0;line-color: #ffffff;line-opacity: 0;}`,
    style_bounds: `#layer {polygon-fill: ramp([u5m], (#461220, #8c2f39, #b23a48, #fcb9b2, #fed0bb), jenks);} #layer::outline {line-width: 1;line-color: #000000;line-opacity: 1;}`,
  },
  {
    name_pixel: "Distance to Roads",
    name_bounds: "Average Distance to Roads",
    style_pixel: `#layer {polygon-fill: ramp([dr], (#264653, #2a9d8f, #e9c46a, #f4a261, #e76f51), jenks);} #layer::outline {line-width: 0;line-color: #ffffff;line-opacity: 0;}`,
    style_bounds: `#layer {polygon-fill: ramp([dr], (#264653, #2a9d8f, #e9c46a, #f4a261, #e76f51), jenks);} #layer::outline {line-width: 1;line-color: #000000;line-opacity: 1;}`,
  },
  {
    name_pixel: "Distance to Towns",
    name_bounds: "Average Distance to Towns",
    style_pixel: `#layer {polygon-fill: ramp([dt], (#233d4d, #fe7f2d, #fcca46, #a1c181, #619b8a), jenks);} #layer::outline {line-width: 0;line-color: #ffffff;line-opacity: 0;}`,
    style_bounds: `#layer {polygon-fill: ramp([dt], (#233d4d, #fe7f2d, #fcca46, #a1c181, #619b8a), jenks);} #layer::outline {line-width: 1;line-color: #000000;line-opacity: 1;}`,
  },
  {
    name_pixel: "Population Estimate",
    name_bounds: "Population Estimate",
    style_pixel: `#layer {polygon-fill: ramp([pop], (#355070, #6d597a, #b56576, #e56b6f, #eaac8b), jenks);} #layer::outline {line-width: 0;line-color: #ffffff;line-opacity: 0;}`,
    style_bounds: `#layer {polygon-fill: ramp([pop], (#355070, #6d597a, #b56576, #e56b6f, #eaac8b), jenks);} #layer::outline {line-width: 1;line-color: #000000;line-opacity: 1;}`,
  },
  {
    name_pixel: "Relative Wealth Index",
    name_bounds: "Relative Wealth Index",
    style_pixel: `#layer {polygon-fill: ramp([rwi], (#feebe2, #fbb4b9, #f768a1, #c51b8a, #7a0177), jenks);} #layer::outline {line-width: 0;line-color: #ffffff;line-opacity: 0;}`,
    style_bounds: `#layer {polygon-fill: ramp([rwi], (#feebe2, #fbb4b9, #f768a1, #c51b8a, #7a0177), jenks);} #layer::outline {line-width: 1;line-color: #000000;line-opacity: 1;}`,
  },
];
