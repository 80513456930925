export const dem_rep_congo = {
  name: "Democratic Republic of the Congo",
  mapID: "dem_rep_congo",
  lat: -4.0383,
  long: 21.7587,
  zoom: 7,
  boundaries: 3,
  /* 
      you can add as many layers for each indicator. 
      do maintain the same structure for all. 
      */
  layers: [
    {
      name: "Country Border",
      carto_tableName: "cod_adm0",
      carto_layer: null /* we will insert carto's layer object here */,
      carto_style: `#layer {polygon-fill: #826dba; polygon-opacity: 0;} #layer::outline {line-width: 1.75; line-color: #000000; line-opacity: 1;}`,
      visible: true,
      filters: [],
    },
    {
      name: "1x1km area",
      carto_tableName: "cod_class",
      carto_source: null,
      carto_layer: null /* we will insert carto's layer object here */,
      carto_style: `#layer {polygon-fill: ramp([class], (#3d4bc7, #4f9130, #bf4343, #c49755), (1, 2, 3, 4), '=', category);} #layer::outline {line-width: 0;line-color: #ffffff;line-opacity: 0;}`,
      visible: false,
      accessCounter: new Set(null),
      washCounter: new Set(null),
      socioCounter: new Set(null),
      healthCounter: new Set(null),
      filters: [
        {
          name: "Rural Typology",
          unit: "",
          type: "categorical",
          column_name: "class",
          subcategory: "accessibility",
          value: [
            {
              name: "Rural Remote",
              value: 1,
              checked: true,
            },
            {
              name: "Rural On-road",
              value: 2,
              checked: true,
            },
            {
              name: "Rural Mixed",
              value: 3,
              checked: true,
            },
            {
              name: "Urban",
              value: 4,
              checked: true,
            },
          ],
        },
      ],
    },
    {
      name: "5x5km area",
      carto_tableName: "cod_multivariable_pixel2",
      carto_source: null,
      carto_layer: null,
      carto_style: `#layer {polygon-fill: ramp([class], (#3d4bc7, #4f9130, #bf4343, #c49755), (1, 2, 3, 4), '=', category);} #layer::outline {line-width: 0;line-color: #ffffff;line-opacity: 0;}`,
      visible: true,
      accessCounter: new Set(null),
      washCounter: new Set(null),
      socioCounter: new Set(null),
      healthCounter: new Set(null),
      prefix: "p_",
      filters: [
        {
          name: "Rural Typology",
          unit: "",
          type: "categorical",
          column_name: "class",
          subcategory: "accessibility",
          value: [
            {
              name: "Rural Remote",
              value: 1,
              checked: true,
            },
            {
              name: "Rural On-road",
              value: 2,
              checked: true,
            },
            {
              name: "Rural Mixed",
              value: 3,
              checked: true,
            },
            {
              name: "Urban",
              value: 4,
              checked: true,
            },
          ] /* declaure col values that should be filtered on */,
        },
        {
          name: "Population Practicing Open Defecation",
          unit: "%",
          type: "range",
          column_name: "od",
          min: 0,
          max: 94,
          value: [0, 94],
          subcategory: "wash",
        },
        {
          name: "Travel Time to Cities",
          unit: "min.",
          type: "range",
          column_name: "timec",
          min: 0,
          max: 2892,
          value: [0, 2892],
          subcategory: "accessibility",
        },
        {
          name: "Diahrrea Prevalence in Children <5 Years",
          unit: "%",
          type: "range",
          column_name: "dia",
          min: 2.3,
          max: 7.7,
          value: [2.3, 7.7],
          subcategory: "health",
        },
        {
          name: "Predicted Annual Cholera Incidence",
          unit: "cases/100,000pp",
          type: "range",
          column_name: "chol",
          min: 0,
          max: 13698.1,
          value: [0, 13698.1],
          subcategory: "health",
        },
        {
          name: "Reliance on Unimproved Sanitation",
          unit: "%",
          type: "range",
          column_name: "s_uimp",
          min: 0,
          max: 99,
          value: [0, 99],
          subcategory: "wash",
        },
        {
          name: "Reliance on Unimproved Drinking Water",
          unit: "%",
          type: "range",
          column_name: "w_uimp",
          min: 0,
          max: 99,
          value: [0, 99],
          subcategory: "wash",
        },
        {
          name: "Women's Educational Attainment",
          unit: "yrs.",
          type: "range",
          column_name: "eduw",
          min: 1,
          max: 13,
          value: [1, 13],
          subcategory: "socioeconomic",
        },
        {
          name: "Men's Educational Attainment",
          unit: "yrs.",
          type: "range",
          column_name: "edum",
          min: 2,
          max: 13,
          value: [2, 13],
          subcategory: "socioeconomic",
        },
        {
          name: "Mortality in Children <5 Years",
          unit: "%",
          type: "range",
          column_name: "u5m",
          min: 3.4,
          max: 15.4,
          value: [3.4, 15.4],
          subcategory: "health",
        },
        {
          name: "Distance to Roads",
          unit: "km.",
          type: "range",
          column_name: "dr",
          min: 0,
          max: 110.3,
          value: [0, 110.3],
          subcategory: "accessibility",
        },
        {
          name: "Distance to Towns",
          unit: "km.",
          type: "range",
          column_name: "dt",
          min: 0,
          max: 213.2,
          value: [0, 213.2],
          subcategory: "accessibility",
        },
        {
          name: "Population Estimate",
          unit: "people",
          type: "range_non_linear",
          column_name: "pop",
          min: 0,
          max: 70, //we want 7 breaks not counting start value of 0.
          value: [0, 70], //slider range will be from 0-70, which we will scale to
          scaledMin: 0,
          scaledMax: 989467,
          scaledValue: [0, 989467], //the actual min/max of column
          subcategory: "socioeconomic",
          // define 7+1 non linear marks here, note that value goes from 0-70 only
          marks: [
            {
              value: 0,
              scaledValue: 0,
              label: "0",
            },
            {
              value: 10,
              scaledValue: 100,
              label: "100",
            },
            {
              value: 20,
              scaledValue: 500,
              label: "500",
            },
            {
              value: 30,
              scaledValue: 1000,
              label: "1K",
            },
            {
              value: 40,
              scaledValue: 5000,
              label: "5K",
            },
            {
              value: 50,
              scaledValue: 50000,
              label: "50K",
            },
            {
              value: 60,
              scaledValue: 100000,
              label: "100K",
            },
            {
              value: 70,
              scaledValue: 1000000,
              label: "1M",
            },
          ],
        },
        {
          name: "Rural Remote",
          unit: "%",
          type: "none",
          column_name: "rr",
          subcategory: "accessibility",
        },
        {
          name: "Rural On-road",
          unit: "%",
          type: "none",
          column_name: "rrd",
          subcategory: "accessibility",
        },
        {
          name: "Rural Mixed",
          unit: "%",
          type: "none",
          column_name: "rm",
          subcategory: "accessibility",
        },
        {
          name: "Urban",
          unit: "%",
          type: "none",
          column_name: "u",
          subcategory: "accessibility",
        },
        {
          name: "Province",
          type: "none",
          column_name: "name_1",
          subcategory: "id",
        },
        {
          name: "Territory",
          type: "none",
          column_name: "name_2",
          subcategory: "id",
        },
        {
          name: "Chiefdom/Sector",
          type: "none",
          column_name: "name_3",
          subcategory: "id",
        },
      ],
    },
    {
      name: "Chiefdom/Sector",
      carto_tableName: "cod_multivariable_adm2",
      carto_source: null,
      carto_layer: null,
      carto_style: `#layer {polygon-fill: ramp([class], (#3d4bc7, #4f9130, #bf4343, #c49755), (1, 2, 3, 4), '=', category);} #layer::outline {line-width: 1;line-color: #000000;line-opacity: 1;}`,
      visible: false,
      accessCounter: new Set(null),
      washCounter: new Set(null),
      socioCounter: new Set(null),
      healthCounter: new Set(null),
      prefix: "adm2_",
      filters: [
        {
          name: "Predominant Rural Typology",
          unit: "",
          type: "categorical",
          column_name: "class",
          value: [
            {
              name: "Predominantly Rural Remote",
              value: 1,
              checked: true,
            },
            {
              name: "Predominantly Rural on-road",
              value: 2,
              checked: true,
            },
            {
              name: "Predominantly Rural Mixed",
              value: 3,
              checked: true,
            },
            {
              name: "Predominantly Urban",
              value: 4,
              checked: true,
            },
          ] /* declaure col values that should be filtered on */,
          subcategory: "accessibility",
        },
        {
          name: "Average Population Practicing Open Defecation",
          unit: "%",
          type: "range",
          column_name: "od",
          min: 5,
          max: 37,
          value: [5, 37],
          subcategory: "wash",
        },
        {
          name: "Average Travel Time to Cities",
          unit: "min.",
          type: "range",
          column_name: "timec",
          min: 2,
          max: 1684,
          value: [2, 1684],
          subcategory: "accessibility",
        },
        {
          name: "Average Diahrrea Prevalence in Children <5 Years",
          unit: "%",
          type: "range",
          column_name: "dia",
          min: 3.5,
          max: 6.9,
          value: [3.5, 6.9],
          subcategory: "health",
        },
        {
          name: "Average Predicted Annual Cholera Incidence",
          unit: "cases/100,000pp",
          type: "range",
          column_name: "chol",
          min: 0,
          max: 1784,
          value: [0, 1784],
          subcategory: "health",
        },
        {
          name: "Average Reliance on Unimproved Sanitation",
          unit: "%",
          type: "range",
          column_name: "s_uimp",
          min: 18,
          max: 68,
          value: [18, 68],
          subcategory: "wash",
        },
        {
          name: "Access to Any Improved Sanitation",
          unit: "%",
          type: "range",
          column_name: "s_imp",
          min: 15,
          max: 69,
          value: [15, 69],
          subcategory: "wash",
        },
        {
          name: "Access to Non-Piped Improved Sanitation",
          unit: "%",
          type: "range",
          column_name: "s_io",
          min: 14,
          max: 65,
          value: [14, 65],
          subcategory: "wash",
        },
        {
          name: "Access to Sewer/Septic Sanitation",
          unit: "%",
          type: "range",
          column_name: "s_pipe",
          min: 0,
          max: 22,
          value: [0, 22],
          subcategory: "wash",
        },
        {
          name: "Average Reliance on Unimproved Drinking Water",
          unit: "%",
          type: "range",
          column_name: "w_uimp",
          min: 9,
          max: 84,
          value: [9, 84],
          subcategory: "wash",
        },
        {
          name: "Access to Any Improved Drinking Water",
          unit: "%",
          type: "range",
          column_name: "w_imp",
          min: 12,
          max: 90,
          value: [12, 90],
          subcategory: "wash",
        },
        {
          name: "Access to Non-Piped Improved Drinking Water",
          unit: "%",
          type: "range",
          column_name: "w_io",
          min: 7,
          max: 66,
          value: [7, 66],
          subcategory: "wash",
        },
        {
          name: "Access to Piped Drinking Water",
          unit: "%",
          type: "range",
          column_name: "w_pipe",
          min: 1,
          max: 59,
          value: [1, 59],
          subcategory: "wash",
        },
        {
          name: "Reliance on Surface Water",
          unit: "%",
          type: "range",
          column_name: "w_sur",
          min: 1,
          max: 27,
          value: [1, 27],
          subcategory: "wash",
        },
        {
          name: "Average Women's Educational Attainment",
          unit: "yrs.",
          type: "range",
          column_name: "eduw",
          min: 3,
          max: 9,
          value: [3, 9],
          subcategory: "socioeconomic",
        },
        {
          name: "Average Men's Educational Attainment",
          unit: "yrs.",
          type: "range",
          column_name: "edum",
          min: 5,
          max: 10,
          value: [5, 10],
          subcategory: "socioeconomic",
        },
        {
          name: "Average Mortality in Children <5 Years",
          unit: "%",
          type: "range",
          column_name: "u5m",
          min: 5.0,
          max: 14.4,
          value: [5.0, 14.4],
          subcategory: "health",
        },
        {
          name: "Average Distance to Roads",
          unit: "km.",
          type: "range",
          column_name: "dr",
          min: 0.8,
          max: 75.1,
          value: [0.8, 75.1],
          subcategory: "accessibility",
        },
        {
          name: "Average Distance to Towns",
          unit: "km.",
          type: "range",
          column_name: "dt",
          min: 0.3,
          max: 180.2,
          value: [0.3, 180.2],
          subcategory: "accessibility",
        },
        {
          name: "Population Estimate",
          unit: "people",
          type: "range_non_linear",
          column_name: "pop",
          scaledMin: 0,
          scaledMax: 7817076,
          scaledValue: [0, 7817076],
          subcategory: "socioeconomic",
          min: 0,
          max: 60,
          value: [0, 60],
          marks: [
            {
              value: 0,
              scaledValue: 500,
              label: "500",
            },
            {
              value: 10,
              scaledValue: 10000,
              label: "10K",
            },
            {
              value: 20,
              scaledValue: 100000,
              label: "100K",
            },
            {
              value: 30,
              scaledValue: 500000,
              label: "500K",
            },
            {
              value: 40,
              scaledValue: 1000000,
              label: "1M",
            },
            {
              value: 50,
              scaledValue: 4000000,
              label: "4M",
            },
            {
              value: 60,
              scaledValue: 8000000,
              label: "8M",
            },
          ],
        },
        //   {
        //     name: "Average Relative Wealth Index",
        //     unit: "index",
        //     type: "range_non_linear",
        //     column_name: "rwi",
        //     scaledMin: -0.53,
        //     scaledMax: 1.35,
        //     scaledValue: [-0.53, 1.35],
        //     subcategory: "socioeconomic",
        //     min: 0,
        //     max: 60,
        //     value: [0, 60], //the actual min/max of column
        //     marks: [
        //       {
        //         value: 0,
        //         scaledValue: -0.53,
        //         label: "-0.53",
        //       },
        //       {
        //         value: 10,
        //         scaledValue: -0.25,
        //         label: "-0.25",
        //       },
        //       {
        //         value: 20,
        //         scaledValue: 0,
        //         label: "0",
        //       },
        //       {
        //         value: 30,
        //         scaledValue: 0.25,
        //         label: "0.25",
        //       },
        //       {
        //         value: 40,
        //         scaledValue: 0.6,
        //         label: "0.6",
        //       },
        //       {
        //         value: 50,
        //         scaledValue: 1,
        //         label: "1",
        //       },
        //       {
        //         value: 60,
        //         scaledValue: 1.4,
        //         label: "1.4",
        //       },
        //     ],
        //   },
        {
          name: "Rural Remote",
          unit: "%",
          type: "none",
          column_name: "rr",
          subcategory: "accessibility",
        },
        {
          name: "Rural On-road",
          unit: "%",
          type: "none",
          column_name: "rrd",
          subcategory: "accessibility",
        },
        {
          name: "Rural Mixed",
          unit: "%",
          type: "none",
          column_name: "rm",
          subcategory: "accessibility",
        },
        {
          name: "Urban",
          unit: "%",
          type: "none",
          column_name: "u",
          subcategory: "accessibility",
        },
        {
          name: "Province",
          type: "none",
          column_name: "name_1",
          subcategory: "id",
        },
        {
          name: "Territory",
          type: "none",
          column_name: "name_2",
          subcategory: "id",
        },
        {
          name: "Chiefdom/Sector",
          type: "none",
          column_name: "name_3",
          subcategory: "id",
        },
      ],
    },
    {
      name: "Health Zone",
      carto_tableName: "cod_multivariable_adm2a",
      carto_source: null,
      carto_layer: null,
      carto_style: `#layer {polygon-fill: ramp([class], (#3d4bc7, #4f9130, #bf4343, #c49755), (1, 2, 3, 4), '=', category);} #layer::outline {line-width: 1;line-color: #000000;line-opacity: 1;}`,
      visible: false,
      accessCounter: new Set(null),
      washCounter: new Set(null),
      socioCounter: new Set(null),
      healthCounter: new Set(null),
      prefix: "adm2a_",
      filters: [
        {
          name: "Predominant Rural Typology",
          unit: "",
          type: "categorical",
          column_name: "class",
          value: [
            {
              name: "Predominantly Rural Remote",
              value: 1,
              checked: true,
            },
            {
              name: "Predominantly Rural on-road",
              value: 2,
              checked: true,
            },
            {
              name: "Predominantly Rural Mixed",
              value: 3,
              checked: true,
            },
            {
              name: "Predominantly Urban",
              value: 4,
              checked: true,
            },
          ] /* declaure col values that should be filtered on */,
          subcategory: "accessibility",
        },
        {
          name: "Average Population Practicing Open Defecation",
          unit: "%",
          type: "range",
          column_name: "od",
          min: 0,
          max: 42,
          value: [0, 42],
          subcategory: "wash",
        },
        {
          name: "Average Travel Time to Cities",
          unit: "min.",
          type: "range",
          column_name: "timec",
          min: 0,
          max: 1250,
          value: [0, 1250],
          subcategory: "accessibility",
        },
        {
          name: "Average Diahrrea Prevalence in Children <5 Years",
          unit: "%",
          type: "range",
          column_name: "dia",
          min: 0,
          max: 6.8,
          value: [0, 6.8],
          subcategory: "health",
        },
        {
          name: "Average Predicted Annual Cholera Incidence",
          unit: "cases/100,000pp",
          type: "range",
          column_name: "chol",
          min: 0,
          max: 2500,
          value: [0, 2500],
          subcategory: "health",
        },
        {
          name: "Average Reliance on Unimproved Sanitation",
          unit: "%",
          type: "range",
          column_name: "s_uimp",
          min: 0,
          max: 78,
          value: [0, 78],
          subcategory: "wash",
        },
        // {
        //   name: "Access to Any Improved Sanitation",
        //   unit: "%",
        //   type: "range",
        //   column_name: "s_imp",
        //   min: 15,
        //   max: 69,
        //   value: [15, 69],
        //   subcategory: "wash",
        // },
        // {
        //   name: "Access to Non-Piped Improved Sanitation",
        //   unit: "%",
        //   type: "range",
        //   column_name: "s_io",
        //   min: 14,
        //   max: 65,
        //   value: [14, 65],
        //   subcategory: "wash",
        // },
        // {
        //   name: "Access to Sewer/Septic Sanitation",
        //   unit: "%",
        //   type: "range",
        //   column_name: "s_pipe",
        //   min: 0,
        //   max: 22,
        //   value: [0, 22],
        //   subcategory: "wash",
        // },
        {
          name: "Average Reliance on Unimproved Drinking Water",
          unit: "%",
          type: "range",
          column_name: "w_uimp",
          min: 0,
          max: 80,
          value: [0, 80],
          subcategory: "wash",
        },
        // {
        //   name: "Access to Any Improved Drinking Water",
        //   unit: "%",
        //   type: "range",
        //   column_name: "w_imp",
        //   min: 12,
        //   max: 90,
        //   value: [12, 90],
        //   subcategory: "wash",
        // },
        // {
        //   name: "Access to Non-Piped Improved Drinking Water",
        //   unit: "%",
        //   type: "range",
        //   column_name: "w_io",
        //   min: 7,
        //   max: 66,
        //   value: [7, 66],
        //   subcategory: "wash",
        // },
        // {
        //   name: "Access to Piped Drinking Water",
        //   unit: "%",
        //   type: "range",
        //   column_name: "w_pipe",
        //   min: 1,
        //   max: 59,
        //   value: [1, 59],
        //   subcategory: "wash",
        // },
        // {
        //   name: "Reliance on Surface Water",
        //   unit: "%",
        //   type: "range",
        //   column_name: "w_sur",
        //   min: 1,
        //   max: 27,
        //   value: [1, 27],
        //   subcategory: "wash",
        // },
        {
          name: "Average Women's Educational Attainment",
          unit: "yrs.",
          type: "range",
          column_name: "eduw",
          min: 3,
          max: 14,
          value: [3, 14],
          subcategory: "socioeconomic",
        },
        {
          name: "Average Men's Educational Attainment",
          unit: "yrs.",
          type: "range",
          column_name: "edum",
          min: 5,
          max: 13,
          value: [5, 13],
          subcategory: "socioeconomic",
        },
        {
          name: "Average Mortality in Children <5 Years",
          unit: "%",
          type: "range",
          column_name: "u5m",
          min: 0,
          max: 14,
          value: [0, 14],
          subcategory: "health",
        },
        {
          name: "Average Distance to Roads",
          unit: "km.",
          type: "range",
          column_name: "dr",
          min: 0,
          max: 45.4,
          value: [0, 45.4],
          subcategory: "accessibility",
        },
        {
          name: "Average Distance to Towns",
          unit: "km.",
          type: "range",
          column_name: "dt",
          min: 0,
          max: 169.2,
          value: [0, 169.2],
          subcategory: "accessibility",
        },
        {
          name: "Population Estimate",
          unit: "people",
          type: "range_non_linear",
          column_name: "pop",
          scaledMin: 0,
          scaledMax: 3189412,
          scaledValue: [0, 3189412],
          subcategory: "socioeconomic",
          min: 0,
          max: 60,
          value: [0, 60],
          marks: [
            {
              value: 0,
              scaledValue: 500,
              label: "500",
            },
            {
              value: 10,
              scaledValue: 10000,
              label: "10K",
            },
            {
              value: 20,
              scaledValue: 100000,
              label: "100K",
            },
            {
              value: 30,
              scaledValue: 500000,
              label: "500K",
            },
            {
              value: 40,
              scaledValue: 1000000,
              label: "1M",
            },
            {
              value: 50,
              scaledValue: 2000000,
              label: "2M",
            },
            {
              value: 60,
              scaledValue: 3200000,
              label: "3.2M",
            },
          ],
        },
        //   {
        //     name: "Average Relative Wealth Index",
        //     unit: "index",
        //     type: "range_non_linear",
        //     column_name: "rwi",
        //     scaledMin: -0.53,
        //     scaledMax: 1.35,
        //     scaledValue: [-0.53, 1.35],
        //     subcategory: "socioeconomic",
        //     min: 0,
        //     max: 60,
        //     value: [0, 60], //the actual min/max of column
        //     marks: [
        //       {
        //         value: 0,
        //         scaledValue: -0.53,
        //         label: "-0.53",
        //       },
        //       {
        //         value: 10,
        //         scaledValue: -0.25,
        //         label: "-0.25",
        //       },
        //       {
        //         value: 20,
        //         scaledValue: 0,
        //         label: "0",
        //       },
        //       {
        //         value: 30,
        //         scaledValue: 0.25,
        //         label: "0.25",
        //       },
        //       {
        //         value: 40,
        //         scaledValue: 0.6,
        //         label: "0.6",
        //       },
        //       {
        //         value: 50,
        //         scaledValue: 1,
        //         label: "1",
        //       },
        //       {
        //         value: 60,
        //         scaledValue: 1.4,
        //         label: "1.4",
        //       },
        //     ],
        //   },
        {
          name: "Rural Remote",
          unit: "%",
          type: "none",
          column_name: "rr",
          subcategory: "accessibility",
        },
        {
          name: "Rural On-road",
          unit: "%",
          type: "none",
          column_name: "rrd",
          subcategory: "accessibility",
        },
        {
          name: "Rural Mixed",
          unit: "%",
          type: "none",
          column_name: "rm",
          subcategory: "accessibility",
        },
        {
          name: "Urban",
          unit: "%",
          type: "none",
          column_name: "u",
          subcategory: "accessibility",
        },
        {
          name: "Province",
          type: "none",
          column_name: "name_1",
          subcategory: "id",
        },
        {
          name: "Territory",
          type: "none",
          column_name: "name_2",
          subcategory: "id",
        },
        {
          name: "Health Zone",
          type: "none",
          column_name: "name_3",
          subcategory: "id",
        },
      ],
    },
    {
      name: "Province",
      carto_tableName: "cod_multivariable_adm1",
      carto_layer: null /* we will insert carto's layer object here */,
      carto_source: null,
      carto_style: `#layer {polygon-fill: ramp([class], (#3d4bc7, #4f9130, #bf4343, #c49755), (1, 2, 3, 4), '=', category);} #layer::outline {line-width: 1;line-color: #000000;line-opacity: 1;}`,
      accessCounter: new Set(null),
      washCounter: new Set(null),
      socioCounter: new Set(null),
      healthCounter: new Set(null),
      visible: false,
      type: "adm1",
      filters: [
        {
          name: "Rural Typology",
          unit: "",
          type: "categorical",
          column_name: "class",
          subcategory: "accessibility",
          value: [
            {
              name: "Rural Remote",
              value: 1,
              checked: true,
            },
            {
              name: "Rural On-road",
              value: 2,
              checked: true,
            },
            {
              name: "Rural Mixed",
              value: 3,
              checked: true,
            },
            {
              name: "Urban",
              value: 4,
              checked: true,
            },
          ] /* declaure col values that should be filtered on */,
        },
        {
          name: "Population Practicing Open Defecation",
          unit: "%",
          type: "range",
          column_name: "od",
          min: 20,
          max: 30,
          value: [20, 30],
          subcategory: "wash",
        },
        {
          name: "Travel Time to Cities",
          unit: "min.",
          type: "range",
          column_name: "timec",
          min: 115,
          max: 816,
          value: [115, 816],
          subcategory: "accessibility",
        },
        {
          name: "Diahrrea Prevalence in Children <5 Years",
          unit: "%",
          type: "range",
          column_name: "dia",
          min: 3.9,
          max: 5.8,
          value: [3.9, 5.8],
          subcategory: "health",
        },
        {
          name: "Predicted Annual Cholera Incidence",
          unit: "cases/100,000pp",
          type: "range",
          column_name: "chol",
          min: 0.2,
          max: 94.7,
          value: [0.2, 94.7],
          subcategory: "health",
        },
        {
          name: "Reliance on Unimproved Sanitation",
          unit: "%",
          type: "range",
          column_name: "s_uimp",
          min: 31,
          max: 46,
          value: [31, 46],
          subcategory: "wash",
        },
        {
          name: "Access to Any Improved Sanitation",
          unit: "%",
          type: "range",
          column_name: "s_imp",
          min: 26,
          max: 45,
          value: [26, 45],
          subcategory: "wash",
        },
        {
          name: "Access to Non-Piped Improved Sanitation",
          unit: "%",
          type: "range",
          column_name: "s_io",
          min: 25,
          max: 43,
          value: [25, 43],
          subcategory: "wash",
        },
        {
          name: "Access to Sewer/Septic Sanitation",
          unit: "%",
          type: "range",
          column_name: "s_pipe",
          min: 1,
          max: 9,
          value: [1, 9],
          subcategory: "wash",
        },
        {
          name: "Reliance on Unimproved Drinking Water",
          unit: "%",
          type: "range",
          column_name: "w_uimp",
          min: 54,
          max: 63,
          value: [54, 63],
          subcategory: "wash",
        },
        {
          name: "Access to Any Improved Drinking Water",
          unit: "%",
          type: "range",
          column_name: "w_imp",
          min: 26,
          max: 36,
          value: [26, 36],
          subcategory: "wash",
        },
        {
          name: "Access to Non-Piped Improved Drinking Water",
          unit: "%",
          type: "range",
          column_name: "w_io",
          min: 17,
          max: 27,
          value: [17, 27],
          subcategory: "wash",
        },
        {
          name: "Access to Piped Drinking Water",
          unit: "%",
          type: "range",
          column_name: "w_pipe",
          min: 6,
          max: 12,
          value: [6, 12],
          subcategory: "wash",
        },
        {
          name: "Reliance on Surface Water",
          unit: "%",
          type: "range",
          column_name: "w_sur",
          min: 10,
          max: 15,
          value: [10, 15],
          subcategory: "wash",
        },
        {
          name: "Women's Educational Attainment",
          unit: "yrs.",
          type: "range",
          column_name: "eduw",
          min: 3,
          max: 7,
          value: [3, 7],
          subcategory: "socioeconomic",
        },
        {
          name: "Men's Educational Attainment",
          unit: "yrs.",
          type: "range",
          column_name: "edum",
          min: 6,
          max: 9,
          value: [6, 9],
          subcategory: "socioeconomic",
        },
        {
          name: "Mortality in Children <5 Years",
          unit: "%",
          type: "range",
          column_name: "u5m",
          min: 6.1,
          max: 10.7,
          value: [6.1, 10.7],
          subcategory: "health",
        },
        {
          name: "Distance to Roads",
          unit: "km.",
          type: "range",
          column_name: "dr",
          min: 4.3,
          max: 24.6,
          value: [4.3, 24.6],
          subcategory: "accessibility",
        },
        {
          name: "Distance to Towns",
          unit: "km.",
          type: "range",
          column_name: "dt",
          min: 6.5,
          max: 102.3,
          value: [6.5, 102.3],
          subcategory: "accessibility",
        },
        {
          name: "Population Estimate",
          unit: "people",
          type: "range",
          column_name: "pop",
          min: 195878,
          max: 10743545,
          value: [195878, 10743545], //the actual min/max of column
          subcategory: "socioeconomic",
        },
        {
          name: "Rural Remote",
          unit: "%",
          type: "none",
          column_name: "rr",
          subcategory: "accessibility",
        },
        {
          name: "Rural On-road",
          unit: "%",
          type: "none",
          column_name: "rrd",
          subcategory: "accessibility",
        },
        {
          name: "Rural Mixed",
          unit: "%",
          type: "none",
          column_name: "rm",
          subcategory: "accessibility",
        },
        {
          name: "Urban",
          unit: "%",
          type: "none",
          column_name: "u",
          subcategory: "accessibility",
        },
        {
          name: "Province",
          type: "none",
          column_name: "name_1",
          subcategory: "id",
        },
      ],
    },
    {
      name: "Estimated settlement",
      carto_tableName: "cod_multivariable_comms_join1",
      carto_layer: null /* we will insert carto's layer object here */,
      carto_source: null,
      carto_style: `#layer {polygon-fill: #826dba; polygon-opacity: 0;} #layer::outline {line-width: 1; line-color: #000000; line-opacity: 1;}`,
      accessCounter: new Set(null),
      washCounter: new Set(null),
      socioCounter: new Set(null),
      healthCounter: new Set(null),
      visible: false,
      filters: [
        {
          name: "Rural Typology",
          unit: "",
          type: "categorical",
          column_name: "class",
          subcategory: "accessibility",
          value: [
            {
              name: "Rural Remote",
              value: 1,
              checked: true,
            },
            {
              name: "Rural On-road",
              value: 2,
              checked: true,
            },
            {
              name: "Rural Mixed",
              value: 3,
              checked: true,
            },
            {
              name: "Urban",
              value: 4,
              checked: true,
            },
          ] /* declaure col values that should be filtered on */,
        },
        {
          name: "Travel Time to Cities",
          unit: "min.",
          type: "range",
          column_name: "timec",
          min: 0,
          max: 2521,
          value: [0, 2521],
          subcategory: "accessibility",
        },
        {
          name: "Distance to Roads",
          unit: "km.",
          type: "range",
          column_name: "dr",
          min: 0,
          max: 97.4,
          value: [0, 97.4],
          subcategory: "accessibility",
        },
        {
          name: "Distance to Towns",
          unit: "km.",
          type: "range",
          column_name: "dt",
          min: 0,
          max: 213.9,
          value: [0, 213.9],
          subcategory: "accessibility",
        },
        {
          name: "Population Estimate",
          unit: "people",
          type: "range_non_linear",
          column_name: "pop",
          min: 0,
          max: 70, //we want 7 breaks not counting start value of 0.
          value: [0, 70], //slider range will be from 0-70, which we will scale to
          scaledMin: 0,
          scaledMax: 7485342,
          scaledValue: [0, 7485342], //the actual min/max of column
          subcategory: "socioeconomic",
          // define 7+1 non linear marks here, note that value goes from 0-70 only
          marks: [
            {
              value: 0,
              scaledValue: 0,
              label: "0",
            },
            {
              value: 10,
              scaledValue: 500,
              label: "500",
            },
            {
              value: 20,
              scaledValue: 1000,
              label: "1K",
            },
            {
              value: 30,
              scaledValue: 5000,
              label: "5K",
            },
            {
              value: 40,
              scaledValue: 50000,
              label: "50K",
            },
            {
              value: 50,
              scaledValue: 100000,
              label: "100K",
            },
            {
              value: 60,
              scaledValue: 1000000,
              label: "1M",
            },
            {
              value: 70,
              scaledValue: 7500000,
              label: "7.5M",
            },
          ],
        },
        {
          name: "Rural Remote",
          unit: "%",
          type: "none",
          column_name: "rr",
          subcategory: "accessibility",
        },
        {
          name: "Rural On-road",
          unit: "%",
          type: "none",
          column_name: "rrd",
          subcategory: "accessibility",
        },
        {
          name: "Rural Mixed",
          unit: "%",
          type: "none",
          column_name: "rm",
          subcategory: "accessibility",
        },
        {
          name: "Urban",
          unit: "%",
          type: "none",
          column_name: "u",
          subcategory: "accessibility",
        },
        {
          name: "Province",
          type: "none",
          column_name: "name_1",
          subcategory: "id",
        },
        {
          name: "Territory",
          type: "none",
          column_name: "name_2",
          subcategory: "id",
        },
        {
          name: "Chiefdom/Sector",
          type: "none",
          column_name: "name_3",
          subcategory: "id",
        },
      ],
    },
  ],
};
